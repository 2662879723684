import React, { useState } from 'react';
import { toast } from 'react-toastify'; 
import "react-toastify/dist/ReactToastify.css";  
import "../css/Global.css";
import Footer from './Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:5000/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);  
                setFormData({ name: '', email: '', mobile: '', message: '' });
            } else {
                toast.error(data.error || "Submission failed.");  
            }
        } catch (error) {
            toast.error("An error occurred. Please try again."); 
        }
    };

    return (
        <>
            <div className="contact-component">
                <h2>Contact us</h2>
            </div>
            <div className="contact-section">
                <div className="contact-container">
                    <div className="contact-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3103816208386!2d72.8302627752069!3d19.13788628207946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b70073902eef%3A0x429d02349dd18ff3!2sMorya%20Blue%20Moon!5e0!3m2!1sen!2sin!4v1731755070789!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name
                                <input type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                Email
                                <input type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required />
                            </label>
                            <label>
                                Mobile Number
                                <input type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} required />
                            </label>
                            <label>
                                Message
                                <textarea name="message" placeholder="Enter your message" value={formData.message} onChange={handleChange} required></textarea>
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
