import React from 'react';
import '../css/Global.css';
import ustreamshadow from '../img/Stream.png';
import ustream from '../img/Stream_01.png';
import musicshadow from '../img/Music.png';
import music from '../img/Music_01.png';
import digitalshadow from '../img/Digital.png';
import digital from '../img/Digital_01.png';
import studioshadow from '../img/Studios.png';
import studio from '../img/Studios_01.png';
import productionshadow from '../img/Productions.png';
import production from '../img/Productions_01.png';
import distributionshadow from '../img/Distribution.png';
import distribution from '../img/Distribution_01.png';
import eventsshadow from '../img/Event.png';
import events from '../img/Event_01.png';
import liveshadow from '../img/LIVE.png';
import live from '../img/LIVE_01.png';
import cinemashadow from '../img/Cinema.png';
import cinema from '../img/Cinema_01.png';
import mediashadow from '../img/MEDIA.png';
import media from '../img/MEDIA_01.png';
import magazineshadow from '../img/Magazine.png';
import magazine from '../img/Magazine_01.png';
import originalshadow from '../img/Originals.png';
import original from '../img/Originals_01.png';
import buttonU from '../img/WEB_DATA/Button_U.png';
import Additional from './Additiona';
import Ulogo from '../img/U_LOGO_02.jpg';
import R from '../img/WEB_DATA/R.png';
import Footer from './Footer';

const Home = () => {
  return (
    <>
      <div className="main-home">
        <h1><img src={Ulogo} alt="U Entertainment Logo" />Entertainment.com</h1>
        <h3>group sites</h3>
        <div className="underline"></div>
      </div>

      {/* Card Section */}
      <section className="card-section">
        
        {/* STREAM */}
        <div className="card">
          <a href="https://stream.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={ustreamshadow} alt="U Stream Shadow" />
              <img className="card-img-hover" src={ustream} alt="U Stream" />
            </div>
          </a>
          <a href="https://stream.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>STREAM</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

        {/* MUSIC */}
        <div className="card">
          <a href="https://music.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={musicshadow} alt="U Music Shadow" />
              <img className="card-img-hover" src={music} alt="U Music" />
            </div>
          </a>
          <a href="https://music.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>MUSIC</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

        {/* DIGITAL */}
        <div className="card">
          <a href="https://digital.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={digitalshadow} alt="U Digital Shadow" />
              <img className="card-img-hover" src={digital} alt="U Digital" />
            </div>
          </a>
          <a href="https://digital.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>DIGITAL</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

        {/* MAGAZINE */}
        <div className="card">
          <a href="https://magazine.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={magazineshadow} alt="U Magazine Shadow" />
              <img className="card-img-hover" src={magazine} alt="U Magazine" />
            </div>
          </a>
          <a href="https://magazine.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>MAGAZINE</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* MEDIA */}
         <div className="card">
          <a href="https://media.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={mediashadow} alt="U Media Shadow" />
              <img className="card-img-hover" src={media} alt="U Media" />
            </div>
          </a>
          <a href="https://media.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>MEDIA</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* PRODUCTION */}
         <div className="card">
          <a href="https://production.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={productionshadow} alt="U Production Shadow" />
              <img className="card-img-hover" src={production} alt="U Production" />
            </div>
          </a>
          <a href="https://productions.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>PRODUCTIONS</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* CINEMA */}
         <div className="card">
          <a href="https://cinema.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={cinemashadow} alt="U Cinema Shadow" />
              <img className="card-img-hover" src={cinema} alt="U Cinema" />
            </div>
          </a>
          <a href="https://cinema.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>CINEMA</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* EVENTS */}
         <div className="card">
          <a href="https://events.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={eventsshadow} alt="U Events Shadow" />
              <img className="card-img-hover" src={events} alt="U Events" />
            </div>
          </a>
          <a href="https://events.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>EVENTS</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

        {/* STUDIO */}
        <div className="card">
          <a href="https://studios.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={studioshadow} alt="U Studio Shadow" />
              <img className="card-img-hover" src={studio} alt="U Studio" />
            </div>
          </a>
          <a href="https://studio.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>STUDIOS</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* ORIGINALS */}
         <div className="card">
          <a href="https://originals.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={originalshadow} alt="U Originals Shadow" />
              <img className="card-img-hover" src={original} alt="U Originals" />
            </div>
          </a>
          <a href="https://originals.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>ORIGINALS</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

         {/* LIVE */}
         <div className="card">
          <a href="https://live.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={liveshadow} alt="U Live Shadow" />
              <img className="card-img-hover" src={live} alt="U Live" />
            </div>
          </a>
          <a href="https://live.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>LIVE</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

        {/* DISTRIBUTION */}
        <div className="card">
          <a href="https://distribution.uentertainment.com/">
            <div className="card-img-container">
              <img className="card-img" src={distributionshadow} alt="U Distribution Shadow" />
              <img className="card-img-hover" src={distribution} alt="U Distribution" />
            </div>
          </a>
          <a href="https://distribution.uentertainment.com/">
            <div className="card-title">
              <img src={buttonU} alt="U Button" />
              <p>DISTRIBUTION</p>
              <img src={R} alt="R Icon" className="r_img" />
            </div>
          </a>
        </div>

      </section>

      <Additional />

      <Footer/>
    </>
  );
};

export default Home;
