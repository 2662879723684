import '../css/Global.css';
import Ulogo from '../img/U_LOGO_02.jpg';
import aboutImg from '../img/big_img.jpg';
import Footer from './Footer';

const About = () => {
    return (
        <>
            <div className="about-component">
                <h1>About <img src={Ulogo} alt="" />Entertainment</h1>
                <p>This is a paragraph about the company.</p>
                <img src={aboutImg} alt="" className='about-img' />
            </div>
            <div className="about-content">
                <h2>Overview</h2>
                <p>
                    UEntertainment is an innovative media and entertainment company, deeply engaged in diverse sectors such as live streaming, film and television production, content distribution, original series, digital music, magazine publications, state-of-the-art studios, and event management. Our mission is to captivate audiences with fresh, high-quality content across multiple platforms, delivering a seamless blend of creativity and technology that defines modern entertainment.
                </p>
                <h3>Facilities and Capabilities</h3>
                <p>
                    Headquartered in a cutting-edge facility, UEntertainment boasts an expansive studio setup equipped with advanced production and post-production technologies. Our facilities are designed to handle high-volume, high-quality productions efficiently, allowing us to produce everything from live broadcasts to scripted shows and music recordings. With dedicated spaces for set construction, editing suites, and sound stages, we are equipped to bring diverse and immersive content to life.
                </p>
                <h3>Production and Quality Standards</h3>
                <p>
                Our team of skilled creatives, technicians, and industry experts work tirelessly to uphold the highest production standards. From on-set direction to post-production editing, every stage is crafted with precision to deliver premium quality entertainment that resonates with viewers. We are also progressing toward industry-standard certifications, underscoring our commitment to quality and excellence in every project.
                </p>
                <h2>Why Choose UEntertainment?</h2>
                <p>
                UEntertainment offers a comprehensive suite of entertainment services that span live streaming, original productions, digital music, and events, all supported by state-of-the-art facilities and an experienced team. Our cutting-edge studios and technology ensure that every project, from small-scale digital media to large productions, meets the highest quality standards. With dedicated spaces and advanced equipment, we deliver seamlessly across multiple platforms to provide an all-in-one solution for diverse media needs.
                </p>
                <h2>Our Vision</h2>
                <p>
                UEntertainment envisions becoming a leading force in global entertainment, inspiring audiences through innovative, high-quality content that transcends boundaries. Our goal is to redefine media experiences by seamlessly blending technology with creativity, crafting stories and experiences that resonate deeply with diverse audiences. We aim to be the preferred destination for immersive and original entertainment, fostering a world where creativity, connection, and cultural richness thrive on every screen and stage.
                </p>
            </div>
            <Footer/>
        </>
    );
};

export default About;