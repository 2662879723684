import React, { useState } from 'react';
import '../css/Global.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleSubscribe = async () => {
        if (!email) {
            toast.error("Please enter a valid email address.");
            return;
        }

        if (!isChecked) {
            toast.error("Please agree to the Terms and Conditions.");
            return;
        }

        try {
            const response = await fetch('http://localhost:5000/subscribe', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();

            if (response.ok) {
                toast.success("Subscribed successfully!");
            } else {
                toast.error(data.error || "Subscription failed.");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

            <footer>
                <div className="footer-links">
                    <ul>
                        <li><Link to='/'>Site Map</Link></li> <div className="vertical"></div>
                        <li><Link to='/about-us'>About Us</Link></li> <div className="vertical"></div>
                        <li><Link to='/contact'>Contact Us</Link></li> <div className="vertical"></div>
                        <li><Link to='/'>TERMS AND CONDITIONS</Link></li> <div className="vertical"></div>
                        <li><Link to='/'>PRIVACY & POLICY</Link></li>
                    </ul>
                </div>

                <div className="line-divide"></div>
                <div className="footer-input">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="subscribe-button">
                        <button onClick={handleSubscribe}>SUBSCRIBE</button>
                    </div>
                </div>

                <div className="footer-checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <span></span>
                    </label>
                    <p>I have read and agree to the <Link to='/'>Terms of Service & Privacy Policy</Link></p>
                </div>

                <p className="copyright">
                    <b>Copyright © 2024 U Entertainment | All Rights Reserved. <br />
                        Created by <a href='https://100billiontech.com'>100Billion Tech</a></b>
                </p>
            </footer>
        </>
    );
};

export default Footer;
